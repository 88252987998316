<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default{
  name:'App'
}

</script>

<style>
@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  /* Puedes ajustar los formatos y las propiedades de la fuente según sea necesario */
}

body {
  font-family: 'Roboto-Light';
}
</style>
