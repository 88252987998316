import { createRouter,createWebHistory } from 'vue-router';
import '../assets/fonts/Roboto-Light.ttf';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import ('../views/HomePage.vue')
  },
  {
    path: '/contacto',
    name: 'contact',
    component: () => import ('../views/ContactPage.vue')
  },
  {
    path: '/preguntas',
    name: 'preguntas',
    component: () => import ('../views/FrequentQuestionsPage.vue')
  },
  {
    path: '/lugares',
    name: 'places',
    component: () => import ('../views/PlacesPage.vue')
  },
  {
    path: '/servicios',
    name: 'services',
    component: () => import ('../views/ServicesPage.vue')
  },
  {
    path: '/historia',
    name: 'historia',
    component: () => import ('../views/HistoryPage.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import ('../views/BlogPage.vue')
  },
  {
    path: '/apertura',
    name: 'apertura',
    component: () => import ('../views/GrandOpeningPage.vue')
  },
  {
    path: '/melaoorquesta',
    name: 'melaoorquesta',
    component: () => import ('../views/MelaoOrchestaPage.vue')
  },
  {
    path: '/feliznavidad',
    name: 'feliznavidad',
    component: () => import ('../views/MerryChristmasPage.vue')
  },
  {
    path: '/nuestrahistoria',
    name: 'nuestrahistoria',
    component: () => import ('../views/OurHistoryPage.vue')
  },
  {
    path: '/servicioaereo',
    name: 'servicioaereo',
    component: () => import ('../views/AirServicePage.vue')
  },
  {
    path: '/serviciofarmacia',
    name: 'serviciofarmacia',
    component: () => import ('../views/PharmacyServicePage.vue')
  },
  {
    path: '/serviciorestaurante',
    name: 'serviciorestaurante',
    component: () => import ('../views/RestaurantServicePage.vue')
  },
  {
    path: '/servicioembarque',
    name: 'serviciorembarque',
    component: () => import ('../views/EmbarkServicePage.vue')
  },
  {
    path: '/serviciotienda',
    name: 'serviciotienda',
    component: () => import ('../views/ShopServicePage.vue')
  },
  {
    path: '/serviciotransferencia',
    name: 'serviciotransferencia',
    component: () => import ('../views/BankServicePage.vue')
  },
  {
    path: '/serviciopanaderia',
    name: 'serviciopanaderia',
    component: () => import ('../views/BakeryServicePage.vue')
  },
  {
    path: '/serviciocarniceria',
    name: 'serviciocarniceria',
    component: () => import ('../views/ButcherShopServicePage.vue')
  },
  {
    path: '/serviciosaloneventos',
    name: 'serviciosaloneventos',
    component: () => import ('../views/EventHallServicePage.vue')
  },
  {
    path: '/cotizador',
    name: 'cotizador',
    component: () => import ('../views/QuoterPage.vue')
  },
  {
    path: '/rastreador',
    name: 'rastreador',
    component: () => import ('../views/TrackerPage.vue')
  },
  {
    path: '/misionyvision',
    name: 'misionyvision',
    component: () => import ('../views/MissionAndVisionPage.vue')
  }
]

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
